import * as React from 'react';
import { Button, CssBaseline, TextField, Box, Typography, Container, Alert } from '@mui/material';

export default function Login({ loginFn, message }) {
  return (
    <Container component='main' maxWidth='sm'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src='/logo_256.png' alt='mLang 動語文' />
        <Typography component='h1' variant='h5'>
          mLang AI 中文作文評改平台
        </Typography>
        <Box component='form' onSubmit={loginFn} noValidate sx={{ mt: 1 }}>
          <TextField margin='normal' required fullWidth id='username' label='登入名稱' name='username' autoFocus />
          <TextField margin='normal' required fullWidth name='password' label='密碼' type='password' id='password' />
          {message && <Alert severity='warning'>{message}</Alert>}
          <Button type='submit' fullWidth variant='contained' color='primary' size='large' sx={{ mt: 3, mb: 2 }}>
            登入
          </Button>
        </Box>
      </Box>

      <Box sx={{ mt: 3 }}>&copy; 動中文方案有限公司 m-Chinese Solution Limited 2024</Box>
    </Container>
  );
}
