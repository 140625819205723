import './App.css';
import { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Box,
  Card,
  TextField,
  Backdrop,
  CircularProgress,
  Select,
  MenuItem,
  Alert,
  Button,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';
import Header from './components/Header';
import Result from './components/Result';
import Login from './components/Login';
import HistoryDrawer from './components/History';
import Usage from './components/Usage';

function App() {
  const [token, setToken] = useState(null);
  const [uid, setUid] = useState(null);
  const [name, setName] = useState(null);
  const [text, setText] = useState('');
  const [teacher, setTeacher] = useState(true);
  const [result, setResult] = useState(null);
  const [model, setModel] = useState('gpt4');
  const [grade, setGrade] = useState(1);
  const [genre, setGenre] = useState('');
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState('teacher');
  const [developer, setDeveloper] = useState(false);
  const [error, setError] = useState(null);
  const [history, setHistory] = useState(null);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [usage, setUsage] = useState(null);

  const handleLogin = async (event) => {
    event.preventDefault();
    setError(null);
    const data = new FormData(event.currentTarget);

    await fetch(`${process.env.REACT_APP_API_HOST}/v2/user/auth`, {
      method: 'POST',
      headers: {
        username: data.get('username'),
        password: data.get('password'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error > 0) {
          setError('登入名稱或密碼不正確');
        } else {
          setToken(data.token);
          setUid(data.userId);
          setName(data.name);
          setRole(data.role);
          console.log(data.role);
          if (data.role === 'student') {
            setTeacher(false);
            setDeveloper(false);
          } else if (data.role === 'developer') {
            setDeveloper(true);
          }
        }
      })
      .catch((err) => {
        console.error(err.message);
        setError(err.message);
      });
  };

  const handleLogout = (event) => {
    setToken(null);
    setModel('gpt4');
    setUid(null);
    setName(null);
  };

  const handleInput = (event) => {
    setText(event.target.value);
  };

  const handleSwitch = (event) => {
    setTeacher(!event.target.checked);
  };

  const handleSwitchModel = (event) => {
    setModel(event.target.checked ? 'gpt4' : 'ChatGPT');
  };

  const handleGradeChange = (event) => {
    setGrade(event.target.value);
  };

  const handleGenreChange = (event) => {
    setGenre(event.target.value);
  };

  const gen = async () => {
    setLoading(true);
    setError(null);
    await fetch(`${process.env.REACT_APP_API_HOST}/v2/writing/generate?ts=${new Date().getTime()}`, {
      method: 'POST',
      body: JSON.stringify({
        topic: text.trim(),
        role: role === 'student' ? 'student' : teacher ? 'teacher' : developer ? 'developer' : 'student',
        userId: uid,
        model: model,
        grade: grade,
        genre: genre,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.error > 0) {
          setError(data.message);
        } else {
          setResult(data);
          await getUsage();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err.message);
        setError(err.message);
        setLoading(false);
      });
  };

  const mark = async () => {
    setLoading(true);
    setError(null);
    await fetch(`${process.env.REACT_APP_API_HOST}/v2/writing/mark?ts=${new Date().getTime()}`, {
      method: 'POST',
      body: JSON.stringify({
        writing: text.trim(),
        role: role === 'student' ? 'student' : teacher ? 'teacher' : developer ? 'developer' : 'student',
        userId: uid,
        model: model,
        grade: grade,
        genre: genre,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.error > 0) {
          setError(data.message);
        } else {
          setResult(data);
          await getUsage();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err.message);
        setError(err.message);
        setLoading(false);
      });
  };

  const viewHistory = async () => {
    await fetch(`${process.env.REACT_APP_API_HOST}/v2/writing/history`, {
      method: 'POST',
      body: JSON.stringify({
        page: 1,
        limit: 12,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error > 0) {
          setError(data.message);
        } else {
          setHistory(data.history);
          setHistoryOpen(true);
        }
      })
      .catch((err) => {
        console.error(err.message);
        setError(err.message);
      });
  };

  const selectResult = (resultData) => {
    if (resultData) {
      setText(resultData.input);
      resultData.result = resultData.output;
      setResult(resultData);
      setHistoryOpen(false);
    }
  };

  const getUsage = async () => {
    await fetch(`${process.env.REACT_APP_API_HOST}/v2/writing/getUsage`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error > 0) {
          setError(data.message);
        } else {
          setUsage(data);
        }
      })
      .catch((err) => {
        console.error(err.message);
        setError(err.message);
      });
  };

  const downloadText = async () => {
    if (!result || (!result.id && !result._id)) {
      return null;
    }
    await fetch(`${process.env.REACT_APP_API_HOST}/v2/writing/text?ts=${new Date().getTime()}`, {
      method: 'POST',
      body: JSON.stringify({
        id: result.id ?? result._id,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const blob = new Blob([data.text], { type: 'text/plain;charset=utf-8' });
        const filename = data.type === 'mark' ? '評改作文' : data.role === 'teacher' ? '生成範文' : '作文建議';
        saveAs(blob, filename);
      })
      .catch((err) => {
        console.error(err.message);
        setError(err.message);
      });
  };

  useEffect(() => {
    if (token) {
      const onLoad = async () => {
        await getUsage();
      };
      onLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (!token || !uid) {
    return <Login loginFn={handleLogin} message={error} />;
  }

  return (
    <>
      <Header
        username={name}
        logoutFn={handleLogout}
        genFn={gen}
        markFn={mark}
        genText={teacher ? '生成範文' : '作文建議'}
        historyFn={viewHistory}
        switchModelFn={handleSwitchModel}
        model={model}
        role={role}
        teacher={teacher}
        switchRoleFn={handleSwitch}
        loading={loading}
      />
      <Container>
        <Grid container sx={{ mt: 8, pt: 3 }}>
          {error && (
            <Alert severity='error' sx={{ width: '100%', my: 3 }}>
              {error}
            </Alert>
          )}
          <Grid item xs={12} md={3}>
            <Select
              id='grade'
              value={grade}
              /* label='小學年級'  */ onChange={handleGradeChange}
              sx={{ width: '100%' }}
            >
              <MenuItem value={1}>小學一年級</MenuItem>
              <MenuItem value={2}>小學二年級</MenuItem>
              <MenuItem value={3}>小學三年級</MenuItem>
              <MenuItem value={4}>小學四年級</MenuItem>
              <MenuItem value={5}>小學五年級</MenuItem>
              <MenuItem value={6}>小學六年級</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} md={3}>
            <Select
              id='genre'
              value={genre}
              /* label='文體'  */ onChange={handleGenreChange}
              sx={{ width: '100%' }}
              displayEmpty
            >
              <MenuItem value=''>
                <em>不指定文體</em>
              </MenuItem>
              <MenuItem value={'描寫文'}>描寫文</MenuItem>
              <MenuItem value={'說明文'}>說明文</MenuItem>
              <MenuItem value={'記敍文'}>記敍文</MenuItem>
              <MenuItem value={'抒情文'}>抒情文</MenuItem>
              <MenuItem value={'議論文'}>議論文</MenuItem>
              {/* <MenuItem value={'書信'}>實用文 - 書信</MenuItem> */}
            </Select>
          </Grid>
          <Grid item xs={12} md={5} sx={{ alignSelf: 'center' }}>
            <Usage data={usage} developer={developer} />
          </Grid>
          <Grid item xs={12} md={1} sx={{ alignSelf: 'center' }}>
            <Button aria-label='下載' size='large' color='primary' variant="contained" disabled={result===null} onClick={downloadText} fullWidth>
              <DownloadIcon />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%' }}>
              <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'stretch' }}>
                <TextField
                  multiline
                  fullWidth
                  sx={{ width: '100%', height: '100%', display: 'flex' }}
                  rows={18}
                  placeholder='請輸入作文題目以生成範文 或 輸入整篇文章以進行評改'
                  label='作文題目 或 整篇文章'
                  value={text}
                  onChange={handleInput}
                  variant='filled'
                />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', whiteSpace: 'pre-wrap', bgcolor: grey[100], p: 2, boxSizing: 'border-box' }}>
              <Result data={result} />
            </Card>
          </Grid>
        </Grid>

        <HistoryDrawer historyData={history} state={historyOpen} setDrawer={setHistoryOpen} resultFn={selectResult} />

        {/* <Box sx={{ mt: 2, textAlign: 'center' }}>
        <ButtonGroup variant='contained' size='large'>
          <Button onClick={gen}>
            <Typography variant='h4'>{teacher ? '生成範文' : '作文建議'}</Typography>
          </Button>
          <Button onClick={mark} color='secondary'>
            <Typography variant='h4'>評改作文</Typography>
          </Button>
        </ButtonGroup>
      </Box> */}

        <Box sx={{ mt: 3 }}>&copy; m-Chinese Solution Limited 2024</Box>
        <Box sx={{ textAlign: 'right' }}>
          <img src='/logo_128.png' alt='mLang' />
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: 999 }} open={loading}>
          <CircularProgress color='warning' />
        </Backdrop>
      </Container>
    </>
  );
}

export default App;
